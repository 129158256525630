.productStock {
    font-size: 0.78rem;
    font-weight: bold;
    padding: 0.3rem 0;
    display: flex;
    align-items: center;

    .inStock {
        color: #008a00;
    }
    .lowStock {
        color: #c78800;
    }

    .outOfStock {
        color: #e12d00;
    }
    .onOrder {
        color: #df7300;
    }
}
