.categoryArticle {
    font-size: 0.85rem;
}

.articleCard {
    border: 0;
    background: #fff;
    border: 1px solid #ccc;

    .articleImage {
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        max-height: 350px;
        aspect-ratio: 545 / 350;
        object-fit: cover;
    }
}

.articleBody {
    margin-top: 1rem;
    padding: 0.5rem 1rem;

    .articleDescription {
        color: #333;
        height: 60px;
        overflow: hidden;
        font-size: 0.9rem;
        margin-top: 0.5rem;
    }

    h3 {
        font-size: 0.92rem;
        line-height: 1.3rem;
        height: 60px;
        font-weight: 500;
        margin-bottom: 1rem;
    }
}

.datePublished {
    font-size: 0.9rem;
    color: #7b7b7b;
}

.articleTitle {
    color: #000;
    font-size: 0.9rem !important;
    font-weight: bold;
}
