.cardFiles {
    background: #fff;
    border: none;
    border-left: 9px solid #ccc;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    padding: 1rem;
    -webkit-box-shadow: 2px 2px 11px -4px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 2px 2px 11px -4px rgba(0, 0, 0, 0.15);
    box-shadow: 2px 2px 11px -4px rgba(0, 0, 0, 0.15);
    margin-bottom: 1.4rem;

    a {
        color: var(--primary-color);
    }
}
