.PageWrapper {
    background: #fff;
    padding: 2rem 0;
}

.BreadCrumbWrapper {
    background: #efefef;
}

.filterTitle {
    color: #fff;
    background: #404040;
    padding: 1rem;
    font-weight: 300;
    margin-bottom: 1rem;
    border-radius: 3px;
}

.cardFilter {
    margin-bottom: 10px;
    box-shadow: 0 1px 5px -1px rgba(0, 0, 0, 0.13);
    background: #fff;
    border-radius: 3px;
    padding: 5px 12px;

    [class="filterSubtitle"] {
        font-size: 0.5rem !important;
    }
}

.filterSidebar {
    [class="form-check"] {
        text-align: left;
        font-size: 0.9rem;
        cursor: pointer;
        line-height: 1.4rem;
    }
}

.cardTitle {
    font-size: 0.95rem;
    font-weight: 500;
    padding: 0.4rem 0;
}

.productCount {
    font-size: 0.85rem;
    padding: 1rem;
}

.pageDescription {
    img {
        max-width: 100%;
        height: auto;
    }
}
