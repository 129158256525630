.TitleCategory {
    font-size: 1.1rem;

    @media (min-width: 768px) {
        margin-bottom: 0.4rem;
        font-size: 1.5rem;
    }
}

.DescriptionCategory {
    font-size: 0.88rem;
    color: #333333;
    line-height: 1.4rem;
    margin-top: 1.5rem;

    @media (min-width: 768px) {
        font-size: 1rem;
        line-height: 1.6rem;
        margin-top: 1.5rem;
    }
}

.CategoryWrapper {
    background: #fff;
    padding: 2rem 0 1rem 0;

    @media (min-width: 768px) {
        padding: 2rem 0;
    }

    .DescriptionCategory {
        img {
            max-width: 100%;
            height: auto;
        }
    }
}
