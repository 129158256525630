.productCard {
    display: block;
    background: #fff;
    border: 0;
    border-radius: 0.2rem;
    box-shadow: 0px 0px 6px 1px rgb(0 0 0 / 15%);
    -webkit-box-shadow: 0px 0px 6px 1px rgb(0 0 0 / 15%);
    -moz-box-shadow: 0px 0px 6px 1px rgb(0 0 0 / 15%);

    .mainImg {
        border-radius: 0.2rem;
        padding: 1rem;
        margin: 0 auto;
        display: block;
        width: 100%;
    }
}

.productPriceWrapper {
    min-height: 40px;
}

.imageWrapper {
    position: relative;

    @media (min-width: 767.98px) {
        height: 225px;
    }

    .labelIcons {
        position: absolute;
        bottom: 10px;
        left: 10px;

        @media (max-width: 576px) {
            scale: 0.7;
            bottom: 0;
            left: 0;
            display: flex;
        }

        .icon {
            margin-bottom: 5px;
            @media (max-width: 576px) {
                margin-bottom: 0;
                margin-right: 3px;
            }

            .warranty {
                overflow: hidden;
                position: relative;

                .value {
                    position: absolute;
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 1;
                    text-align: center;
                    top: 5px;
                    left: 0;
                    width: 100%;

                    .afterValue {
                        font-size: 10px;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .energyClassIcon {
        position: absolute;
        top: 20px;
        right: 0;
    }
}

.productCardInfo {
    padding: 1rem;
}

.productName {
    font-size: 0.8rem;
    line-height: 1.4rem;
    font-weight: 600;
    height: 65px;
    overflow: hidden;
}

.productStock {
    font-size: 0.78rem;
    font-weight: bold;
    padding: 0.3rem 0;
    display: flex;
    align-items: center;
}

.productStock {
    color: #00ba00;
    font-size: 0.8rem;
    font-weight: 600;
}

.productDescription {
    margin-top: 0.4rem;
    color: #333333;
    font-size: 0.8rem;
    height: 80px;
    overflow: hidden;
}

.productPrice {
    color: #d10c0c;
    font-weight: 600;
    font-size: 0.9rem;
}

.productOldPrice {
    font-weight: 400;
    font-size: 0.8rem;
    text-decoration: line-through;
}
